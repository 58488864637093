<template>
	<WwSlideOver
		:slide-over-id="CART_SLIDE_OVER"
		title="Your Cart"
		slide-direction="right"
	>
		<template #header="{ handleHideButtonClick }">
			<div class="relative flex items-start justify-between h-7">
				<div class="flex items-center xs:items-start space-x-2 h-full xs:-mt-0.5">
					<h2
						id="slide-over-title"
						:class="[
							'xs:text-xl text-2xl font-bold text-black mr-2 self-end flex flex-nowrap'
						]"
					>
						Your Cart
					</h2>
					<div class="text-gray-500 mr-2.5 xs:text-sm text-base font-normal h-full xs:mt-1.5 mt-1">
						Subtotal:
					</div>
					<div class="h-full flex xs:text-2xl xs:-mt-1 text-3xl font-bold text-black self-center -mt-2.5">
						${{ subtotal }}
					</div>
				</div>

				<div class="flex items-center ml-3 h-7">
					<button
						type="button"
						class="flex items-center justify-center w-8 h-8 text-black bg-transparent rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-300"
						@click="handleHideButtonClick"
					>
						<span class="sr-only">
							Close panel
						</span>
						<CloseIcon />
					</button>
				</div>
			</div>
		</template>
		<template #body>
			<div class="flex items-center px-4 py-3 border-b border-gray-200 min-h-24 md:px-6">
				<div class="w-1/6">
					<WwImage
						:src="businessImage"
						classes="border border-gray-300 border-solid rounded-md shadow"
						alt="Business Name"
						:fallback-image="DEFAULT_WW_GRAY_LOGO"
						:width="70"
						:height="70"
						:image-path="LISTING_IMAGES"
					/>
				</div>
				<div class="w-2/3 h-full ml-3 text-left">
					<div class="grid justify-center w-full grid-cols-1">
						<div class="w-full text-base font-bold min-h-6">
							{{ businessName }}
						</div>
						<button
							class="w-full text-sm font-semibold text-left text-green-500 rounded hover:underline hover:underline-offset-2 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2"
							@click="handleContinueShoppingClick"
						>
							Continue shopping at {{ businessName }}
						</button>
					</div>
				</div>
				<div
					v-if="isDeliveryOnly"
					class="w-1/6 text-xs font-bold"
				>
					Delivery
				</div>
			</div>
			<div v-if="hasItems">
				<CartProductCardList
					:cart="cart"
					:listing-id="listingId"
				/>
				<div
					v-if="hasDeliveryMinimum"
					class="p-4 text-xs"
				>
					Note: There is a minimum purchase amount [ {{ formattedDeliveryMinimum }} ] for delivery service.
				</div>
			</div>
			<div
				v-else
				class="py-12 text-xl font-bold text-center"
			>
				Your cart is empty
			</div>
		</template>
		<template #footer>
			<div class="p-4 space-y-3 bg-gray-100 md:p-6">
				<button
					:disabled="!hasItems"
					:class="[
						hasItems ? 'bg-green-500 cursor-pointer hover:bg-green-400' : 'bg-gray-300 cursor-not-allowed',
						'w-full flex items-center justify-center uppercase rounded-md border border-transparent h-11 px-4 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2'
					]"
					@click="handleSubmit"
				>
					Proceed To Checkout
				</button>
				<div class="w-full text-xs font-semibold text-left">
					<span class="text-sm font-bold">
						Important message on your order:
					</span>
					Thank you for your order! Please note that <span class="italic uppercase">
						TAXES ARE ESTIMATED.
					</span> Your exact total will be given at time of in-store checkout.
				</div>
			</div>
		</template>
	</WwSlideOver>
</template>

<script async>
import { mapGetters, mapMutations } from 'vuex'

import CartProductCardList from '@/components/cart/CartProductCardList.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'
import WwSlideOver from '@/components/UI/WwSlideOver.vue'
import { LISTING_IMAGES } from '@/constants/images/image-folder-paths.js'
import { DELETE_FROM_CART_MODAL } from '@/constants/modal/names.js'
import { DEFAULT_WW_GRAY_LOGO } from '@/constants/search/defaultImage.js'
import { CART_SLIDE_OVER } from '@/constants/slideOver/names.js'

export default {
	components: {
		WwSlideOver,
		CartProductCardList,
		CloseIcon,
		WwImage: () => import('@/components/multiUse/WwImage.vue')
	},
	props: {
		cart: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			CART_SLIDE_OVER,
			DEFAULT_WW_GRAY_LOGO,
			DELETE_FROM_CART_MODAL,
			LISTING_IMAGES
		}
	},
	computed: {
		...mapGetters('modal', [ 'activeModalId' ]),
		...mapGetters('business', [
			'business', 'deliveryMinimum'
		]),
		hasItems() {
			return !!this.cart.items?.length
		},
		businessName() {
			return this.cart.name || '-'
		},
		isDeliveryOnly() {
			return !!this.cart.settings?.deliveryOnly
		},
		hasDeliveryMinimum() {
			return !!this.cart.settings?.deliveryMinimum
		},
		formattedDeliveryMinimum() {
			return this.cart.settings?.deliveryMinimum?.toFixed(2) || 0
		},
		businessImage() {
			return this.cart?.logo || 'default.png'
		},
		subtotal() {
			const decimalPrice = this.cart?.subtotal?.toFixed(2) || '0'
			if (this.cart.subtotal !== undefined) {
				return decimalPrice.includes('.00') ? decimalPrice.replace('.00', '') : decimalPrice
			} else {
				return '0'
			}
		},
		cartRouteName() {
			return this.cart.url
		},
		listingId() {
			return String(this.cart.listingId)
		}
	},
	watch: {
		activeModalId(newValue) {
			if (newValue === DELETE_FROM_CART_MODAL) {
				this.preventClose()
			} else {
				this.allowClose()
			}
		}
	},
	methods: {
		...mapMutations('slideOver', [
			'preventClose',
			'allowClose',
			'showSlideOver',
			'closeSlideOver'
		]),
		handleContinueShoppingClick() {
			this.$router.push(`${this.cartRouteName}`)
			this.closeSlideOver()
		},
		handleSubmit() {
			this.$router.push(`${this.cartRouteName}/cart`)
		}
	}
}
</script>
